import logo from "./logo.svg";
import "./App.css";

import { useEffect, useState } from "react";
import useHttp from "./hooks/use-http";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ALL_ROUTES from "./util/Route";
import CONSTANTS from "./util/constant/CONSTANTS";
import { isLogin } from "./util/API/function";
import SubscriptionServices from "./util/API/subscriptionService";

const App = () => {
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [subscriptionData, setSubscriptionData] = useState([]);

  // const [loading, setLoading] = useState(true);
  const API = useHttp();

  useEffect(() => {
    if (!isLogin()) return;

    API.sendRequest(CONSTANTS.API.LOGIN.getMe, (res) => {
      setUserDetails(res);
    });

    API.sendRequest(
      CONSTANTS.API.SUBSCRIPTION.get,
      (res) => {
        setSubscriptionData(
          res
          // ?.data?.rows?.find((el) => el?.status == "active")
        );
      },
      null,
      SubscriptionServices
    );
  }, [refresh]);

  const updateProfile = (payload) => {
    (async () => {
      API.sendRequest(
        CONSTANTS.API.LOGIN.adminUpdate,
        (res) => {
          // if (res?.status == "success" ) {

          setRefresh((prev) => !prev);
          // }
        },
        payload,
        null,
        "Your Data Update Successfully"
      );
    })();
  };

  const router = createBrowserRouter(
    ALL_ROUTES({
      // socket,

      // loading,
      user_details: userDetails,
      subscriptionData: subscriptionData,
      is_loading: API.isLoading,

      update_profile: updateProfile,
    })
  );
  return <RouterProvider router={router} />;

  // return (
  //   <div className="App">
  //     {/* <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header> */}
  //     <h1 className="text-3xl font-bold underline">Hello world!</h1>
  //   </div>
  // );
};

export default App;
