import { Card, Col, Divider, Progress, Row } from "antd";
import React from "react";
import { getPercent } from "../../util/API/function";
import { subscriptionSpreadData } from "../../util/spread-function";

const UsageCard = (props) => {
  let { subscriptionData, cardFields } = props;
  // subscriptionData = subscriptionSpreadData(subscriptionData);
  // cardFields = {
  //   ...cardFields,
  //   cardFields:
  //     cardFields?.cardFields?.[
  //       subscriptionData?.planType !== "PayAsYouGo" ? "payasyouGo" : "quotaBase"
  //     ],
  // };
  const FieldLine = (fiel, index, fields) => (
    <>
      <Row className={` tw-flex tw-justify-between`}>
        <p
          className={`${
            fields?.length <= 1
              ? "tw-text-lg  tw-text-[#2838bc] tw-mb-5"
              : "tw-text-base  tw-text-gray-400"
          } tw-font-poppins  tw-font-semibold `}
        >
          {fiel?.label}
        </p>
        <p
          className={`${
            fields?.length <= 1
              ? "tw-text-lg  tw-text-[#2838bc] tw-mb-5"
              : "tw-text-base  tw-text-gray-500"
          } tw-font-poppins tw-font-semibold `}
        >
          {subscriptionData?.[fiel?.field]}
        </p>
      </Row>

      {index === fields?.length - 1 ? null : (
        <Divider className="tw-my-2 tw-border-[1px]" />
      )}
    </>
  );

  return (
    <>
      {/* <Col span={8}> */}
      <Card
        title={
          <p className="!tw-font-poppins !tw-font-semibold tw-text-xl tw-text-black">
            {cardFields?.title}
          </p>
        }
        bordered={false}
        key={"main-container"}
      >
        <Card key={"child1-container"}>
          {/* {subscriptionData?.planType == "PayAsYouGo" ? ( */}
          <Row className="tw-flex tw-items-center tw-justify-center tw-mb-2">
            <Progress
              type="dashboard"
              percent={getPercent(
                subscriptionData?.planQuotaUsed,
                subscriptionData?.planQuota
              )}
              format={(percent) => (
                <>
                  <p>{`${percent}%`}</p>
                  <p className="tw-text-base">remaining</p>
                </>
              )}
              gapDegree={75}
            />
          </Row>
          {/* ) : null} */}
          {cardFields?.cardFields?.map((fields, index) => {
            return subscriptionData?.planType !== "payg" ? (
              <>
                {fields?.length > 1 ? (
                  <Card
                    className={` tw-mb-5`}
                    key={`child3-container-${index}`}
                  >
                    {" "}
                    {fields?.map((fiel, index) =>
                      FieldLine(fiel, index, fields)
                    )}
                  </Card>
                ) : (
                  <>
                    {fields?.map((fiel, index) =>
                      FieldLine(fiel, index, fields)
                    )}
                  </>
                )}
              </>
            ) : null;
            // <>
            //   {fields?.map((fiel, index) => FieldLine(fiel, index, fields))}
            // </>
          })}
        </Card>
      </Card>
      {/* </Col> */}
    </>
  );
};

export default UsageCard;
