import { getFormFiled } from "../API/function";
/* getFormFiled Function Argument 
1. Name
2. Label
3. placeholder
4. Type
5. Required

Note: if you not pass placeholder it will take label automatically
Default Type = text & Required = true
*/

export const profileName = getFormFiled("name", "Name", null, "text", true);

export const roleWise = getFormFiled(
  "role",
  "Role",
  null,
  "select",
  true,
  false,
  (r) => {
    return true;
  },
  [
    { id: "client", Label: "Client", value: "client" },
    { id: "siteViewer", Label: "SiteViewer", value: "siteViewer" },
    { id: "tripCreator", Label: "Trip Creator", value: "tripCreator" },
  ],
  null,
  24,
  null
);
export const name = getFormFiled("name", "Name", null);
export const email = getFormFiled("email", "Email", null);
export const mobile = getFormFiled("mobile", "Mobile No", null, "mobileNumber");
export const customerPassword = getFormFiled(
  "password",
  "Password",
  "Enter Password"
);
export const plantForRoleId = getFormFiled(
  "plantId",
  "Site Name",
  "Site Name",
  "select",
  true,
  false,
  (data, defaultData) => {
    if (
      (data?.role && data?.role === "siteViewer") ||
      (!data?.["role"] &&
        defaultData?.role &&
        defaultData?.role === "siteViewer")
    ) {
      return true;
    } else {
      return false;
    }
  }
);

export const plantId = getFormFiled(
  "plantId",
  "Source Site Name",
  "Select Source Site",
  "select"
);

export const clientForRoleId = getFormFiled(
  "clientId",
  "Customer Name",
  "Customer Name",
  "SingleSelect",
  true,
  false,
  (data, defaultData) => {
    if (
      (data?.role && data?.role === "client") ||
      (!data?.["role"] && defaultData?.role && defaultData?.role === "client")
    ) {
      return true;
    } else {
      return false;
    }
  }
);
