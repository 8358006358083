import {
  CloudServerOutlined,
  BarChartOutlined,
  SettingOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import { BsDatabaseFillGear } from "react-icons/bs";
import { GrOverview } from "react-icons/gr";
import {
  ROUTES,
  appRoot,
  clientRoute,
  projectRoot,
  serviceRoot,
} from "./CONSTANTS";
import { AiOutlineHome, AiOutlineStar } from "react-icons/ai";
import { CgInsights } from "react-icons/cg";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { BiGroup, BiPowerOff, BiSupport } from "react-icons/bi";
import { FiShield } from "react-icons/fi";

import { Link } from "react-router-dom";

export const rightBarMenu = (id, navigate) => {
  return {
    items: [
      {
        id: "1",
        label: (
          <Link
            to={`${clientRoute}/${id}/${ROUTES.ADMIN_PROFILE}`}
            // onClick={() => {
            //   navigate(`profile`);
            // }}
          >
            View Profile
          </Link>
        ),
        icon: <BiGroup />,
      },

      {
        id: "4",
        danger: true,
        label: <Link to={`/logout`}>Logout</Link>,
        icon: <BiPowerOff />,
      },
    ],
  };
};

export const menu = [
  {
    id: "dashboard",
    icon: <AiOutlineHome />,
    label: "Home",
    path: `${clientRoute}/:id`,
  },
  {
    id: "usage_insights",
    icon: <CgInsights />,
    label: "Usage Insights",
    path: `${clientRoute}/:id/${ROUTES.ADMIN_USAGE}`,
  },
  {
    id: "plan",
    icon: <AiOutlineStar />,
    label: "Plans",
    path: `${clientRoute}/:id/${ROUTES.ADMIN_PLAN}`,
  },
  {
    id: "api_Credentials",
    icon: <FiShield />,
    label: "API Credentials",
    path: `${clientRoute}/:id/${ROUTES.ADMIN_CREDENTIALS}`,
  },
  {
    id: "api_Documentation",
    icon: <HiOutlineDocumentDuplicate />,
    label: "API Documentation",
    path: `https://documenter.getpostman.com/view/27156162/2s9YRGx9DP#e3eb94fe-8fe4-4f70-a75a-d08866f570d9`,
  },

  {
    id: "support",
    icon: <BiSupport />,
    label: "Support",
    path: `${clientRoute}/:id/${ROUTES.ADMIN_SUPPORT}`,
  },
];
