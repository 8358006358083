import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// Redux

import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

// availity-reactstrap-validation

// actions

// import images
import { LockOutlined, MailOutlined } from "@ant-design/icons";

import useHttp from "../../hooks/use-http";
import {
  deleteAuthDetails,
  getAuthToken,
  setAuthDetails,
} from "../../util/API/authStorage";
import { Button, Card, Col, Form, Input, Row } from "antd";
import Truck_wallpaper from "../../asset/images/Truck_wallpaper.jpg";
import logo from "../../asset/logo/logo-sm-dark.png";
import CONSTANTS, {
  adminRoute,
  appRoot,
  clientRoute,
  loginRoot,
} from "../../util/constant/CONSTANTS";
import { isLogin, notifications } from "../../util/API/function";
import axios from "axios";

const Login = (props) => {
  const API = useHttp();

  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location?.pathname;

  console.log(location?.search?.trim("?"), location?.search);

  const urlParams = new URLSearchParams(location?.search);

  // Get the value of the "clientId" parameter
  const clientId = urlParams.get("token");
  console.log(urlParams, urlParams.get("token"), urlParams.get("userRole"));
  useEffect(() => {
    // isLogin = getAuthToken() !== undefined && getAuthToken() !== null;
    // if (!isLogin) return notifications.error("Session Expired!");
    console.log(isLogin(), urlParams.get("token"));
    console.log(getAuthToken());
    if (urlParams.get("userRole")) {
      deleteAuthDetails();

      localStorage.clear();
    }
    console.log(isLogin(), urlParams.get("token"));

    if (isLogin()) {
      return navigate(clientRoute);
      // userRole == "admin" ? navigate(adminRoute) : navigate(clientRoute);
    } else {
      if (urlParams.get("token") != null) {
        // debugger;
        try {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}${CONSTANTS.API.LOGIN.getMe.endpoint}`,
              {
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${urlParams.get("token")}`,
                },
              }
            )
            .then((res) => {
              console.log(res);
              localStorage.setItem("authUser", urlParams.get("token"));
              setAuthDetails(urlParams.get("token"));

              localStorage.setItem("userRole", res?.data?.userRole);
              window.location.assign(
                `${clientRoute}/${res?.data?.data?.clientId}`
              );
              // navigate(`${clientRoute}/${res?.data?.data?.clientId}`);
              debugger;
              // window.location.reload();
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (error) {
          console.log(error);
        }
      }

      //  navigate("")
    }
  }, []);

  const validateLogin = (value) => {
    const payload = {
      email: value.email,
      password: value.password,
    };
    // window.location.assign(appRoot);
    // console.log(payload);
    API.sendRequest(
      CONSTANTS.API.LOGIN.login,
      onSetLoginData,
      payload,
      null
      // "Login Successfully"
    );
  };

  const onSetLoginData = (res) => {
    if (res?.userRole == "admin")
      return notifications.error("Incorrect email or password");
    setAuthDetails(res?.token);
    localStorage.setItem("authUser", res?.token);
    localStorage.setItem("userRole", res?.userRole);

    // window.location.assign(appRoot);
    notifications.success("Login Successfully");
    window.location.reload();
    // window.location.reload();
  };

  return (
    <React.Fragment>
      {!isLogin() ? (
        <Row
          className="tw-h-screen tw-flex tw-justify-center tw-bg-[rgba(0,0,0,0.8)] tw-bg-[center_center] tw-bg-cover tw-px-0 tw-py-[100px]"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Truck_wallpaper})`,
          }}
        >
          <Col xs={22} sm={8} md={11} lg={9} xl={7} className=" ">
            <Card className=" tw-overflow-hidden login-card tw-border-0 ">
              <div
                className=" tw-bg-cover tw-bg-[center_center] tw-relative tw-px-0 tw-py-[60px] tw-rounded-[0px_0px_50%_50%] "
                style={{
                  backgroundImage: ` url(${Truck_wallpaper})`,
                }}
              >
                <div
                  className="tw-absolute tw-w-full tw-h-full tw-opacity-80 tw-rounded-[0px_0px_50%_50%] tw-inset-0y tw-text-center tw-top-0"
                  style={{
                    background: `linear-gradient(to right, #273c92, #293e92)`,
                  }}
                ></div>
                <div className=" tw-relative tw-text-center">
                  <p className=" tw-text-white tw-text-[20px] tw-my-1 tw-font-semibold">
                    Welcome Back !
                  </p>
                  <p className=" tw-text-slate-400 tw-my-0">
                    Sign in to continue to Fitsol.
                  </p>
                  <Link
                    to="/"
                    className="logo tw-mt-5 tw-bg-white tw-absolute tw-w-[64px] tw-h-[64px] tw-leading-[85px] tw-text-center tw-shadow-[$box-shadow] tw-mx-auto tw-my-0 tw-rounded-[50%] tw-inset-x-0"
                    style={{
                      boxShadow: "0 0.75rem 1.5rem #12263f33",
                    }}
                  >
                    <img src={logo} alt="" height="30" />
                  </Link>
                </div>
              </div>
              {/* <div className="login-form"> */}{" "}
              <Form
                name="normal_login"
                className="login-form tw-mt-8"
                initialValues={{
                  remember: true,
                }}
                onFinish={validateLogin}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Email!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    type="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item className=" tw-w-full">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button tw-mt-6"
                    loading={API?.isLoading}
                    block
                    // disabled
                  >
                    Log In
                  </Button>
                </Form.Item>
              </Form>
              {/* </div> */}
            </Card>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default Login;
