import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormWithButton from "../../component/common/Form-with-Button";
import CONSTANTS from "../../util/constant/CONSTANTS";
import FormFields from "../../component/common/FormFields";

import { getAuthToken } from "../../util/API/authStorage";
import useHttp from "../../hooks/use-http";
import { MdContentCopy } from "react-icons/md";

const ProfilePage = (props) => {
  const { user_details, is_loading, update_profile } = props;
  const [form] = Form.useForm();

  return (
    <>
      <Row>
        <Col md={24} className="tw-mt-5">
          <Card>
            <FormFields
              formData={user_details?.data}
              menu={"ADMIN_PROFILE"}
              formFields={{}}
              form={form}
              scrollCard={"none"}
            />
            <Button
              className="mt-5"
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    // console.log(values);
                    // onSubmitForm(values);
                    update_profile(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              Update Details
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProfilePage;
