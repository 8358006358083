import React from "react";

const Label = (props) => {
  return props.required ? (
    <p className=" tw-font-medium  font-medium reuired-field">
      {props.children}{" "}
      {props.required && (
        <span
          style={{
            color: "red",
            marginRight: "5px",
          }}
        >
          *
        </span>
      )}
    </p>
  ) : (
    <p className="font-medium title-head">{props.children}</p>
  );
};

export default Label;
