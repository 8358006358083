import { Col, Divider, Row } from "antd";
import React from "react";
import { MdOutlineStarBorder } from "react-icons/md";
import { TbCheck } from "react-icons/tb";
import { planCardDesignCardbg } from "../../util/constant/CONSTANTS";

const PlanCard = (props) => {
  const { planDetail, showModal } = props;
  return (
    <>
      {planDetail?.map((el) => {
        return (
          <>
            <Col lg={8} md={8} sm={24} xs={24} className="tw-mb-5">
              <div
                style={{
                  background: `${
                    el?.highLightCard
                      ? "linear-gradient(161deg, #9B9EE5 -35.07%, #CDCFF2 36.9%, #E6E7F9 93.52%)"
                      : "white"
                  }`,
                }}
                className="card tw-relative tw-overflow-hidden tw-shadow-curved lg:tw-text-start xs:tw-col-span-4 tw-shadow-md lg:tw-col-span-1  tw-rounded-lg"
              >
                {el?.highLightCard ? (
                  <div className="  tw-absolute tw-right-0 tw-h-full">
                    {planCardDesignCardbg}
                  </div>
                ) : (
                  <></>
                )}
                <Col className=" tw-p-6 tw-mx-2 ">
                  <div className=" tw-flex tw-justify-between tw-items-center tw-mb-3">
                    <p
                      className={`${
                        !el?.highLightCard
                          ? "tw-text-[#4D4F67]"
                          : "tw-text-black"
                      }   tw-font-serifB tw-text-2xl lg:tw-text-2xl `}
                    >
                      {/* Trial */}
                      {el?.title}
                    </p>
                    {el?.highLightCard ? (
                      <MdOutlineStarBorder
                        size={20}
                        className=" tw-bg-white tw-rounded-[4px]"
                      />
                    ) : null}
                  </div>
                  <div className=" tw-h-[140px] lg:tw-h-[140px] md:tw-h-[230px] tw-mb-4 tw-leading-6">
                    <p className=" tw-text-[#4D4F67] tw-font-poppins tw-font-normal tw-text-base  ">
                      {el?.description}
                    </p>
                  </div>
                  <Col className=" tw-h-[204px] lg:tw-h-[204px] md:tw-h-[260px]">
                    <Row className=" tw-flex tw-items-center">
                      <p className="tw-pr-2 font-dmserif  tw-text-black tw-text-3xl tw-font-numb tw-font-normal ">
                        {el?.amount}
                      </p>

                      <p className=" tw-text-col14 tw-mt-2 tw-font-poppins">
                        {el?.perPlan ? el?.perPlan : "per month"}
                      </p>
                    </Row>
                    <Divider className=" !tw-border-[1px] tw-border-bordercol tw-mb-1 tw-mt-2" />
                    {el?.planFeatureDetail?.map((fec) => {
                      return (
                        <div className=" tw-flex tw-items-center tw-gap-2 tw-mb-1">
                          <TbCheck color="#059669" width={14} height={11} />
                          <p className=" tw-text-[#4D4F67] tw-font-poppins tw-font-normal tw-text-sm">
                            {fec}
                          </p>
                        </div>
                      );
                    })}
                  </Col>
                  <div
                    className=" tw-cursor-pointer tw-border-[2px] tw-rounded-lg tw-h-[34px] tw-border-primary tw-text-xs tw-text-primary tw-font-semibold tw-flex tw-justify-center tw-items-center hover:tw-bg-[#e6e7f9] hover:tw-text-[#62c941]   "
                    onClick={() => showModal()}
                  >
                    Know more
                  </div>
                </Col>
              </div>
            </Col>
          </>
        );
      })}
    </>
  );
};

export default PlanCard;
