import { Card, Col, Divider, Modal, Row } from "antd";
import React from "react";
import { MdOutlineCurrencyRupee, MdOutlineStarBorder } from "react-icons/md";
import { TbCheck } from "react-icons/tb";
import PlanCard from "../../component/planSelectCard/planCard";
import { FormSubmitAlert } from "../../util/API/function";
import { useState } from "react";
import Loader from "../../component/common/loader";
import { useEffect } from "react";
import { planDetail } from "../../util/constant/CONSTANTS";

const PlanPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sweet_timer, setSweet_timer] = useState(false);
  const [formLoaded, setFormLoaded] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!formLoaded) return;
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "39660215",
          formId: "ce85fa6c-4e2b-4929-9515-1fd1e5ee8f21",
          target: "#hubspotForm",
          onFormReady: () => setFormLoaded(false),
          onFormSubmit: () => setSweet_timer(true),
        });
      }
    });
  }, [isModalOpen]);
  return (
    <>
      <Row gutter={20} className="tw-mt-2 !tw-border-0 ">
        <PlanCard planDetail={planDetail} showModal={showModal} />
      </Row>

      <Modal
        title={<p className=" tw-text-2xl tw-mb-6 ">Got questions</p>}
        open={isModalOpen}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsModalOpen(false)}
      >
        {formLoaded && <Loader />}
        <div id="hubspotForm"></div>
      </Modal>

      {sweet_timer ? (
        <FormSubmitAlert
          title="Thanks for submitting the form!."
          timeout={3000}
          setSweet_timer={setSweet_timer}
          msg=" Please wait while we process your data..."
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default PlanPage;
