import { Navigate } from "react-router-dom";
import AppLayout from "../component/layout/AppLayout";
import {
  ROUTES,
  adminRoute,
  appRoot,
  clientRoute,
  loginRoot,
} from "./constant/CONSTANTS";
import ApiDashboard from "../page/dashbaord";
import PlanPage from "../page/planPage";
import UsagePage from "../page/usagePage";
import SupprtPage from "../page/supportPage";
import ProfilePage from "../page/profilePage";
import Error from "../page/error";
import NoInternet from "../page/noInternet";
import UnAuthorize from "../page/unAutherize";
import Login from "../page/Authentication/Login";
import Logout from "../page/Authentication/Logout";
import CredentialsPage from "../page/credentialsPage";

const LOGIN_ROUTES = [
  {
    // index: true,
    path: "/login",
    element: <Login />,
  },
  {
    // index: true,
    path: "/logout",
    element: <Logout />,
  },
];

const ALL_ROUTES = (appProps) => [
  ...LOGIN_ROUTES,
  {
    path: "/",
    // index: true,
    element: <Navigate to={`/login`} />,
  },
  // {
  //   path: `${adminRoute}`,
  //   element: <AppLayout {...appProps} />,
  //   children: [
  //     {
  //       index: true,
  //       element: <ApiDashboard {...appProps} />,
  //     },
  //     {
  //       path: `${ROUTES.ADMIN_CREDIT}`,
  //       element: <CreditPage {...appProps} />,
  //     },
  //     {
  //       path: `${ROUTES.ADMIN_USAGE}`,
  //       element: <UsagePage {...appProps} />,
  //     },
  //     {
  //       path: `${ROUTES.ADMIN_CREDENTIALS}`,
  //       element: <CredentialsPage {...appProps} />,
  //     },
  //     {
  //       path: `${ROUTES.ADMIN_SUPPORT}`,
  //       element: <SupprtPage {...appProps} />,
  //     },
  //     {
  //       path: `${ROUTES.ADMIN_PROFILE}`,
  //       element: <ProfilePage {...appProps} />,
  //     },
  //   ],
  // },
  {
    path: `${clientRoute}`,
    element: <AppLayout {...appProps} />,
    children: [
      {
        path: ":id", // Child route with a dynamic parameter
        children: [
          // {
          //   index: true, // Default child route for /service/:id
          //   element: <ApiDashboard {...appProps} />,
          // },
          {
            index: true,
            element: <ApiDashboard {...appProps} />,
          },
          {
            path: `${ROUTES.ADMIN_PROFILE}`,
            element: <ProfilePage {...appProps} />,
          },
          {
            path: `${ROUTES.ADMIN_PLAN}`,
            element: <PlanPage {...appProps} />,
          },
          {
            path: `${ROUTES.ADMIN_USAGE}`,
            element: <UsagePage {...appProps} />,
          },
          {
            path: `${ROUTES.ADMIN_CREDENTIALS}`,
            element: <CredentialsPage {...appProps} />,
          },
          {
            path: `${ROUTES.ADMIN_SUPPORT}`,
            element: <SupprtPage {...appProps} />,
          },

          // {
          //   path: "setting", // Child route for /service/:id/log
          //   element: <Setting {...appProps} />,
          // },
        ],
      },
    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize />,
  },
  {
    path: "/noInternet",
    element: <NoInternet />,
  },
  {
    path: "*",
    element: <Navigate to="/error" />,
  },
];

export default ALL_ROUTES;
