import { Card, Divider, Row, Col, Progress, Button } from "antd";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { subscriptionSpreadData } from "../../util/spread-function";

const DetailCard = (props) => {
  let { subscriptionData, cardFields } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  // subscriptionData = subscriptionSpreadData(subscriptionData);

  if (subscriptionData?.planType == "payg") {
    cardFields.cardFields = cardFields?.cardFields.filter(
      (fiel) => fiel.field !== "price"
    );
  }

  return (
    <>
      {/* <Row gutter={16} className="tw-mt-5"> */}
      {/* <Col span={8} className={className}> */}
      <Card
        title={
          <p className="!tw-font-poppins !tw-font-semibold !tw-text-xl ">
            {cardFields?.title}
          </p>
        }
        bordered={false}
        className={` ${
          subscriptionData?.planType !== "payg" ? "!tw-h-auto" : ""
        } lg:tw-h-[400px] md:tw-h-auto sm:tw-h-auto`}
      >
        <Card>
          {cardFields?.cardFields?.map((fields, index) => {
            return (
              <>
                <Row className={`tw-flex tw-justify-between`}>
                  <p className=" tw-text-base tw-text-gray-400  tw-font-poppins  tw-font-semibold">
                    {fields?.label}
                  </p>
                  <p className=" tw-text-base tw-text-gray-500  tw-font-poppins  tw-font-medium">
                    {subscriptionData?.[fields?.field]}
                  </p>
                </Row>

                {index === cardFields?.cardFields?.length - 1 ? null : (
                  <Divider className="tw-my-2 tw-border-[1px]" />
                )}
              </>
            );
          })}
          {cardFields?.helpbtn ? (
            <Row className="tw-flex tw-justify-end tw-mt-8">
              <Button
                className=" tw-text-base "
                onClick={() => navigate(`${currentPath}/support`)}
              >
                Need Help
              </Button>
            </Row>
          ) : null}
        </Card>
      </Card>
      {/* </Col> */}
      {/* <Col span={8}>
          <Card title={card2?.title} bordered={false}>
            <Card>
              {card2?.loader ? (
                <Row className="tw-flex tw-items-center tw-justify-center tw-mb-2">
                  <Progress
                    type="dashboard"
                    percent={
                      +(
                        +(
                          +(
                            subscriptionData?.planQuota -
                            subscriptionData?.planQuotaUsed
                          ) * 100
                        ) / subscriptionData?.planQuota
                      ).toFixed(2)
                    }
                  />
                </Row>
              ) : null}
              {card2?.cardFields?.map((fields, index) => {
                return (
                  <>
                    <Row className=" tw-flex tw-justify-between">
                      <p className=" tw-text-base tw-font-bold tw-text-gray-400">
                        {fields?.label}
                      </p>
                      <p className=" tw-text-base tw-text-gray-500 tw-font-bold">
                        {subscriptionData?.[fields?.field]}
                      </p>
                    </Row>

                    {index === card2?.cardFields?.length - 1 ? null : (
                      <Divider className="tw-my-2 tw-border-[1px]" />
                    )}
                  </>
                );
              })}
            </Card>
          </Card>
        </Col>
        <Col span={8}>
          <Card title={card3?.title} bordered={false}>
            <Card>
              {card3?.cardFields?.map((fields, index) => {
                return (
                  <>
                    <Row className=" tw-flex tw-justify-between">
                      <p className=" tw-text-base tw-font-bold tw-text-gray-400">
                        {fields?.label}
                      </p>
                      <p className=" tw-text-base tw-text-gray-500 tw-font-bold">
                        {subscriptionData?.[fields?.field]}
                      </p>
                    </Row>

                    {index === card3?.cardFields?.length - 1 ? null : (
                      <Divider className="tw-my-2 tw-border-[1px]" />
                    )}
                  </>
                );
              })}
              {card3?.helpbtn ? (
                <Row className="tw-flex tw-justify-end tw-mt-8">
                  <Button
                    className=" tw-text-base "
                    onClick={() => navigate(`${currentPath}/support`)}
                  >
                 
                    Need Help
                  </Button>
                </Row>
              ) : null}
            </Card>
          </Card> 
        </Col> */}

      {/* {cardData?.map((el) => {
          return (
            <>
              <Col span={8}>
                <Card title={el?.title} bordered={false}>
                  <Card>
                    {el?.loader ? (
                      <Row className="tw-flex tw-items-center tw-justify-center tw-mb-2">
                        <Progress
                          type="dashboard"
                          percent={
                            +(
                              +(
                                +(
                                  subscriptionData?.planQuota -
                                  subscriptionData?.planQuotaUsed
                                ) * 100
                              ) / subscriptionData?.planQuota
                            ).toFixed(2)
                          }
                        />
                      </Row>
                    ) : null}

                    {el?.cardFields?.map((fields, index) => {
                      return (
                        <>
                          <Row className=" tw-flex tw-justify-between">
                            <p className=" tw-text-base tw-font-bold tw-text-gray-400">
                              {fields?.label}
                            </p>
                            <p className=" tw-text-base tw-text-gray-500 tw-font-bold">
                              {subscriptionData?.[fields?.field]}
                            </p>
                          </Row>

                          {index === el?.cardFields?.length - 1 ? null : (
                            <Divider className="tw-my-2 tw-border-[1px]" />
                          )}
                        </>
                      );
                    })}
                    {el?.helpbtn ? (
                      <Row className="tw-flex tw-justify-end tw-mt-8">
                        <Button
                          className=" tw-text-base "
                          onClick={() => navigate(`${currentPath}/support`)}
                        >
                        
                          Need Help
                        </Button>
                      </Row>
                    ) : null}
                  </Card>
                </Card>
              </Col>
            </>
          );
        })} */}
      {/* </Row> */}
    </>
  );
};

export default DetailCard;
