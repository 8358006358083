import { Button, Card, Col, Input, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { MdContentCopy } from "react-icons/md";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";
import { useState } from "react";
import UsageServices from "../../util/API/usageService";

const CredentialsPage = () => {
  //   const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  //   const textRef3 = useRef(null);
  const textRef4 = useRef(null);
  const API = useHttp();
  const [credentialData, setCredentialData] = useState();

  const handleButtonClick = async (textRef, copytext) => {
    if (textRef.current == null) return;
    textRef.current.style.display = "block";

    setTimeout(() => {
      textRef.current.style.display = "none";
    }, 5000);
  };

  useEffect(() => {
    API.sendRequest(
      CONSTANTS.API.USAGE.getCredentials,
      (res) => {
        setCredentialData(res);
      },
      null,
      UsageServices
    );
  }, []);

  return (
    <>
      <Card className=" tw-mt-2">
        <p className=" !tw-font-bold tw-mb-3" style={{ fontSize: "24px" }}>
          Credentials
        </p>
        {/* <div>
          <p className=" tw-font-semibold tw-mb-2" style={{ fontSize: "16px" }}>
            Testing Credentials
          </p> */}
        {/* <p>Testing Account ID</p>
          <Row className=" tw-mb-2">
            <Col md={10} className=" tw-mb-2">
              <Input value={"testing id"} />
            </Col>
            <Col md={14}>
              <Row className=" tw-flex tw-items-center tw-gap-2">
                <Button
                  type="primary"
                  className=" tw-flex tw-items-center tw-ml-2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "Copy this text to clipboard"
                    );
                    handleButtonClick(textRef1, "Copy this text to clipboard");
                  }}
                >
                  <MdContentCopy className=" tw-mr-2" />
                  Copy
                </Button>
                <p
                  ref={textRef1}
                  className="mt-4 bg-green-200 p-4 rounded"
                  style={{ display: "none" }}
                >
                  Testing Account ID copied to clipboard.
                </p>
              </Row>
            </Col>
          </Row> */}
        {/* <p>Testing API Key</p>
          <Row>
            <Col md={10} className=" tw-mb-2">
              <Input value={credentialData?.data} disabled />
            </Col>
            <Col md={14}>
              <Row className=" tw-flex tw-items-center tw-gap-2">
                <Button
                  type="primary"
                  className=" tw-flex tw-items-center tw-ml-2"
                  onClick={() => {
                    navigator.clipboard.writeText(credentialData?.data);
                    handleButtonClick(textRef2, credentialData?.data);
                  }}
                >
                  <MdContentCopy className=" tw-mr-2" />
                  Copy
                </Button>
                <p
                  ref={textRef2}
                  className="mt-4 bg-green-200 p-4 rounded"
                  style={{ display: "none" }}
                >
                  Testing API key copied to clipboard.
                </p>
              </Row>
            </Col>
          </Row>
        </div> */}
        {/* <p className=" tw-border-dotted tw-border-b-2 tw-border-black tw-my-6	"></p> */}
        <div>
          <p className=" tw-font-semibold tw-mb-2" style={{ fontSize: "16px" }}>
            Production Credentials
          </p>
          {/* <p>Production Account ID</p>
          <Row className=" tw-mb-2">
            <Col md={10} className=" tw-mb-2">
              <Input value={"PRODUTION ID"} />
            </Col>
            <Col md={14}>
              <Row className=" tw-flex tw-items-center tw-gap-2">
                <Button
                  type="primary"
                  className=" tw-flex tw-items-center tw-ml-2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "Copy this text to clipboard"
                    );
                    handleButtonClick(textRef3, "Copy this text to clipboard");
                  }}
                >
                  <MdContentCopy className=" tw-mr-2" />
                  Copy
                </Button>
                <p
                  ref={textRef3}
                  className="mt-4 bg-green-200 p-4 rounded"
                  style={{ display: "none" }}
                >
                  Production Account ID copied to clipboard.
                </p>
              </Row>
            </Col>
          </Row> */}
          <p className=" tw-mb-1">Production API Key</p>
          <Row>
            <Col md={10} className=" tw-mb-2">
              <Input value={credentialData?.data} disabled />
            </Col>
            <Col md={14}>
              <Row className=" tw-flex tw-items-center tw-gap-2">
                <Button
                  type="primary"
                  className=" tw-flex tw-items-center tw-ml-2"
                  onClick={() => {
                    navigator.clipboard.writeText(credentialData?.data);
                    handleButtonClick(textRef4, credentialData?.data);
                  }}
                >
                  <MdContentCopy className=" tw-mr-2" />
                  Copy
                </Button>
                <p
                  ref={textRef4}
                  className="mt-4 bg-green-200 p-4 rounded"
                  style={{ display: "none" }}
                >
                  Production API key copied to clipboard.{" "}
                </p>
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default CredentialsPage;
