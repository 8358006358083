import { notification } from "antd";

import { getAuthToken } from "./authStorage";
import { TABLE_CONFIG } from "../table-config";
import SweetAlert from "react-bootstrap-sweetalert";

export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};

export const isLogin = () => {
  return getAuthToken() !== undefined && getAuthToken() !== null;
};
export const getItem = (label, key, icon, children) => {
  return {
    key,
    icon,
    children,
    label,
  };
};

export const getFormFiled = (
  name = "",
  Label = "",
  placeholder = null,
  type = "text",
  required = true,
  disabled = false,
  isShow = () => {
    return true;
  },
  option = [],
  helpText = null,
  width = 24,
  extraValue = null
) => {
  if (placeholder == null) {
    placeholder = Label;
  }
  return {
    id: name,
    name,
    Label,
    placeholder,
    type,
    required,
    disabled,
    isShow,
    option,
    helpText,
    width,
    extraValue,
  };
};

export const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

export const menuGen = (path) => {
  return [
    ...path?.map((el) =>
      getItem(
        el.label,
        `${el.path}`,
        el?.icon,
        el.submenu &&
          el.submenu.map((elp) =>
            getItem(
              elp.label,
              `${el?.path}/${elp.path}`,
              elp?.icon,
              elp.submenu &&
                elp.submenu.map((elpd) =>
                  getItem(elpd?.label, `${elpd.path}`, elpd.icon)
                )
            )
          )
      )
    ),
  ];
};

export const formatMoney = (amount) => {
  if (amount < 1000) {
    return "₹" + amount.toString();
  } else if (amount < 1000000) {
    return "₹" + (amount / 1000).toFixed() + "K";
  } else {
    return "₹" + (amount / 1000000).toFixed() + "M";
  }
};
// export const filterData = (data, searchFields, searchValue) => {
//   return data.filter((item) => {
//     return searchFields.some((field) => {
//       if (item[field]) {
//         // Check if the item has the specified field
//         if (Array.isArray(item[field])) {
//           // If it's an array, check if any element matches the search value
//           return item[field].some((value) => value.includes(searchValue));
//         } else {
//           // If it's a string or number, check if it includes the search value
//           return String(item[field]).includes(searchValue);
//         }
//       }
//       return false;
//     });
//   });
// };

export const notifications = {
  success: (message, duration = "3") =>
    notification.success({
      message: "Success",
      description: message,
      duration: duration,
    }),
  error: (message, duration = "3") =>
    notification.error({
      message: "Error",
      description: message,
      duration: duration,
    }),
  warning: (message, duration = "3") =>
    notification.warning({
      message: "Warning",
      description: message,
      duration: duration,
    }),
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
};

export const replaceId = (api = "", id = "") => {
  const URL = { ...api };
  URL.endpoint = URL.endpoint.replace(":id", id);
  return URL;
};

export const getTableColumConfig = (key) => {
  if (key in TABLE_CONFIG && typeof key !== "object") {
    return TABLE_CONFIG[key];
  } else {
    if (typeof key !== "object") {
      return {
        title: key?.label,
        dataIndex: key?.field,
        sorter: (a, b) => alphanumericSort(a, b, key?.field),
        // sort: key?.sort,
        width: 20,
      };
    } else {
      return { ...key };
    }
  }
};

export const getPercent = (value, total) => {
  return +(((total - value) * 100) / total).toFixed(2);
};

const idGenrator = (id, idName, padding) => {
  return `${idName}${id.toString().padStart(padding, "0")}`;
};

export const getRecordId = {
  job: (id) => idGenrator(id, "JOB", 5),
  record: (id) => idGenrator(id, "REC", 5),
  billId: (id) => idGenrator(id, "BILL", 5),

  roleAndAccessRecordId: (id) => idGenrator(id, "TEAM", 4),
};

export const FormSubmitAlert = ({
  title = "",
  timeout = 3000,
  setSweet_timer,
  msg = "",
}) => {
  return (
    <SweetAlert
      title={`${title}`}
      timeout={timeout}
      success
      showConfirm={false}
      onConfirm={() => {
        setSweet_timer(false);
      }}
    >
      {msg}
    </SweetAlert>
  );
};
