import { Alert, Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useHttp from "../../hooks/use-http";

import CONSTANTS, {
  billingDetailCard,
  fixedCard,
  payasyougoCard,
  subscriptionDetailCard,
} from "../../util/constant/CONSTANTS";

import SubscriptionServices from "../../util/API/subscriptionService";

import CRUDComponent from "../../component/common/CRUD-Component";

import {
  BillHistoryMask,
  subscriptionSpreadData,
} from "../../util/spread-function";
import BillHistoryModal from "../planPage/billHistoryModal";
import DetailCard from "./detailCard";
import UsageCard from "./usageCard";
import Loader from "../../component/common/loader";
import { replaceId } from "../../util/API/function";

const ApiDashboard = (props) => {
  let { subscriptionData, is_loading } = props;

  const API = useHttp();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const [billDetailModal, setBillDetailModal] = useState(false);
  const [billDetailHistoryData, setBillDetailHistoryData] = useState([]);
  const [totalMonthlyBillAmount, setTotalMonthlyBillAmount] = useState("00");

  useEffect(() => {
    API.sendRequest(
      replaceId(CONSTANTS.API.BILL.getTotalMonthlyBill, id),
      (res) => {
        setTotalMonthlyBillAmount(res?.billAmount);
      },
      null,
      SubscriptionServices
    );
  }, []);

  subscriptionData = subscriptionSpreadData(
    subscriptionData,
    totalMonthlyBillAmount
  );

  const onBillDetailHistory = (billData) => {
    setBillDetailHistoryData(
      billData?.map((el) => {
        return {
          ...el,
          usage: el?.usage?.toLocaleString(),
          billAmount: `₹${el?.billAmount?.toLocaleString()}`,
        };
      })
    );

    setBillDetailModal(true);
  };

  return (
    <React.Fragment>
      {/* {is_loading && <Loader />} */}
      {is_loading ? (
        <Loader />
      ) : subscriptionData?.planType === "No Active" ? (
        <Row className="tw-mt-5">
          <Alert
            message="No Active Subscription Plan"
            description="It seems like you don't have an active subscription plan at the moment. To access premium features and content, please subscribe to a plan that best fits your needs."
            type="warning"
            className=" tw-w-full"
            action={
              <Button
                size="middle"
                type="primary"
                onClick={() => navigate(`${currentPath}/plan`)}
              >
                Upgrade now
              </Button>
            }
            showIcon
          />
        </Row>
      ) : (
        <Row gutter={[16, 16]} className="tw-mt-5">
          {subscriptionData?.planType !== "payg" ? (
            <>
              <Col xs={24} md={12} lg={12}>
                <UsageCard
                  subscriptionData={subscriptionData}
                  cardFields={fixedCard}
                />
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Col lg={24} className=" tw-mb-4 !tw-p-0">
                  <DetailCard
                    cardFields={subscriptionDetailCard}
                    subscriptionData={subscriptionData}
                  />
                </Col>

                <Col lg={24} className="!tw-p-0">
                  <DetailCard
                    cardFields={billingDetailCard}
                    subscriptionData={subscriptionData}
                  />
                </Col>
              </Col>
            </>
          ) : (
            <>
              <Col md={24} lg={8} sm={24} xs={24}>
                <DetailCard
                  cardFields={payasyougoCard}
                  subscriptionData={subscriptionData}
                />
              </Col>
              <Col md={24} lg={8} sm={24} xs={24} className=" tw-mb-4">
                <DetailCard
                  cardFields={subscriptionDetailCard}
                  subscriptionData={subscriptionData}
                />
              </Col>
              <Col md={24} lg={8} sm={24} xs={24}>
                <DetailCard
                  cardFields={billingDetailCard}
                  subscriptionData={subscriptionData}
                />
              </Col>
            </>
          )}
        </Row>
      )}

      {/* <Row className=" tw-mt-4">
        <Col lg={24} md={24} xl={24}>
          <Card title="Subscription and usage">
            <Row>
              <Col span={24}>
                {false ? (
                  <>
                    <p className=" tw-text-lg tw-font-bold">Current Plan</p>
                    <p className=" tw-text-base tw-font-bold tw-text-gray-400">
                      {subscriptionData?.planType?.toUpperCase() || ""}
                    </p>
                    <Row className=" tw-flex tw-justify-between">
                      <p className=" tw-text-base tw-font-bold tw-text-gray-400">
                        Billable Quota
                      </p>

                      <p className=" tw-text-base tw-text-gray-500 tw-font-bold">
                        {subscriptionData?.planQuota?.toLocaleString() || "00"}
                      </p>
                    </Row>
                    <Divider className="tw-border-[1px]" />
                    {subscriptionData?.plan?.name != "PayAsYouGo" ? (
                      <Row className=" tw-flex tw-justify-between">
                        <p className=" tw-text-lg tw-font-semibold tw-text-[#2838bc]">
                          Total monthly price (in rs)
                        </p>
                        <p className="tw-text-xl tw-font-semibold tw-text-[#2838bc]">
                          {subscriptionData?.plan?.price?.toLocaleString()}/
                          <span>mo</span>
                        </p>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Alert
                    message="No Active Subscription Plan"
                    description="It seems like you don't have an active subscription plan at the moment. To access premium features and content, please subscribe to a plan that best fits your needs."
                    type="warning"
                    showIcon
                  />
                )}
                <Row className=" tw-flex tw-justify-between tw-items-center tw-mt-8 tw-mb-5">
                  {" "}
                  <p className=" tw-text-lg tw-font-bold">
                    Plan Usage{" "}
                    {subscriptionData?.plan?.name != "PayAsYouGo" ? (
                      <span>
                        ( Quota reset on{" "}
                        {moment(subscriptionData?.renewalDate)?.format(
                          "MMM DD, YYYY"
                        )}{" "}
                        )
                      </span>
                    ) : (
                      <></>
                    )}
                  </p>
                </Row>
                <Card>
                  <Row>
                    {subscriptionData?.plan?.name == "PayAsYouGo" ? (
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Row className=" tw-flex tw-justify-between tw-items-center  tw-font-bold ">
                          <p className="tw-text-gray-400">Billable Quota</p>
                          <p className="tw-text-gray-500">
                            {subscriptionData?.planQuotaUsed?.toLocaleString() ||
                              "00"}
                          </p>
                        </Row>
                        <Divider className=" tw-my-2 tw-border-[1px]" />
                        <Row className=" tw-flex tw-justify-between tw-items-center  tw-font-bold">
                          <p className="tw-text-gray-400">Per Quota price</p>
                          <p className="tw-text-gray-500">
                            {subscriptionData?.plan?.price?.toLocaleString()}
                          </p>
                        </Row>
                        <Divider className=" tw-my-2 tw-border-[1px] " />
                        <Row className=" tw-flex tw-justify-between tw-items-center  tw-font-bold">
                          <p className="tw-text-gray-400">Total</p>
                          <p className="tw-text-gray-500">
                            {(
                              subscriptionData?.planQuotaUsed *
                              subscriptionData?.plan?.price
                            )?.toLocaleString()}
                          </p>
                        </Row>
                      </Col>
                    ) : (
                      <>
                        <Col
                          lg={4}
                          md={5}
                          sm={10}
                          xs={24}
                          className=" tw-flex tw-justify-center  md:tw-justify-start"
                        >
                          {" "}
                          <Progress
                            type="dashboard"
                            percent={
                              +(
                                +(
                                  +(
                                    subscriptionData?.planQuota -
                                    subscriptionData?.planQuotaUsed
                                  ) * 100
                                ) / subscriptionData?.planQuota
                              ).toFixed(2)
                            }

                            // gapDegree={30}
                          />
                        </Col>
                        <Col lg={20} md={19} sm={14} xs={24}>
                          <Row className=" tw-flex tw-justify-between tw-items-center  tw-font-bold ">
                            <p className="tw-text-gray-400">Billable Quota</p>
                            <p className="tw-text-gray-500">
                              {subscriptionData?.planQuotaUsed?.toLocaleString() ||
                                "00"}
                            </p>
                          </Row>
                          <Divider className=" tw-my-2 tw-border-[1px]" />
                          <Row className=" tw-flex tw-justify-between tw-items-center  tw-font-bold">
                            <p className="tw-text-gray-400">Remaining Quota</p>
                            <p className="tw-text-gray-500">
                              {(
                                subscriptionData?.planQuota -
                                subscriptionData?.planQuotaUsed
                              )?.toLocaleString() || "00"}
                            </p>
                          </Row>
                          <Divider className=" tw-my-2 tw-border-[1px] " />
                          <Row className=" tw-flex tw-justify-between tw-items-center  tw-font-bold">
                            <p className="tw-text-gray-400">Total</p>
                            <p className="tw-text-gray-500">
                              {subscriptionData?.planQuota?.toLocaleString()}
                            </p>
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>
                </Card>

                <Row className=" tw-flex tw-justify-between tw-items-center tw-mt-8 tw-mb-5  tw-font-bold">
                  {" "}
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <p className=" tw-text-lg tw-font-bold">Billing details </p>
                    <Row className=" tw-flex tw-justify-between tw-items-center tw-mt-4">
                      <p className=" tw-text-base tw-text-gray-400">
                        Billing cycle
                      </p>
                      <p className=" tw-text-base tw-text-gray-500">
                        Next Billing Date{" "}
                        {moment(subscriptionData?.renewalDate)?.format(
                          "MMM DD, YYYY"
                        )}
                      </p>
                    </Row>
                    <Divider className=" tw-my-3 tw-border-[1px]" />
                    <Row className=" tw-flex tw-justify-between tw-items-center ">
                      <p className=" tw-text-base tw-text-gray-400">
                        Billing issues
                      </p>

                      <Button className=" tw-text-base " onClick={showModal}>
                        Need Help
                      </Button>
                    </Row>
                    <Divider className=" tw-my-3 tw-border-[1px]" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row> */}
      <Row className=" tw-mt-10">
        <CRUDComponent
          isSearch
          service={SubscriptionServices}
          GET={{
            API: CONSTANTS.API.BILL.get,
            DataModifier: (res) => {
              return BillHistoryMask(res, onBillDetailHistory);
            },

            column: CONSTANTS.TABLE.BILL_HISTORY_TABLE,
          }}
        />
      </Row>

      {/* <Modal
        title="Contact us"
        open={isModalOpen}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        // afterClose={() => {
        //   console.log("first");
        //   setIsModalOpen(false);
        // }}
        onCancel={() => setIsModalOpen(false)}
      >
        {formLoaded && <Loader />}
        <div id="hubspotForm"></div>
      </Modal> */}

      <BillHistoryModal
        title="Bill Detail"
        open={billDetailModal}
        onCancel={setBillDetailModal}
        data={billDetailHistoryData}
      />

      {/* <Modal
        title="Bill Detail"
        open={isModalOpen}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        // afterClose={() => {
        //   console.log("first");
        //   setIsModalOpen(false);
        // }}
        onCancel={() => setIsModalOpen(false)}
      >
        <Table
          dataSource={dataSource}
          columns={CONSTANTS.TABLE.BILLDETAILMODAL?.map((dataKey) =>
            getTableColumConfig(dataKey)
          )}
        />
        ;
      </Modal> */}

      {/* {sweet_timer ? (
        <FormSubmitAlert
          title="Thanks for submitting the form!."
          timeout={3000}
          setSweet_timer={setSweet_timer}
          msg=" Please wait while we process your data..."
        />
      ) : (
        <></>
      )} */}

      {/* <Row gutter={[16, 16]} className="tw-mt-6">
        {subCategoryData?.map((el, i) => (
          <Col
            span={8}
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            key={el?.title + i}
          >
            <Card size="small" className="tw-p-3" hoverable>
              <Row>
              
                <Col
                  md={18}
                  onClick={() => {
                    if (el?.postmanLink) {
                      window.open(`${el?.postmanLink}`, "_blank");
                    }
                  }}
                >
                  <Meta description={el?.description} />
                  <p className=" tw-mt-2 tw-font-bold tw-text-base">
                    {el?.name}
                  </p>
                  <p className=" tw-mt-4 tw-flex tw-items-center">
                    View{" "}
                    <IoIosArrowRoundForward className=" tw-ml-0 " size={30} />{" "}
                  </p>

                 
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row> */}
    </React.Fragment>
  );
};

export default ApiDashboard;
