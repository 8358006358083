import { Button, Card, Col, Result, Row } from "antd";
import React from "react";

const NoInternet = () => {
  return (
    <>
      <Row className="tw-flex tw-justify-center tw-absolute tw-w-full tw-top-[15%]">
        <Col xs={22} sm={12} md={9} lg={9} xl={6}>
          <Card className=" tw-overflow-hidden">
            <div className="text-center p-3">
              <Result
                status="warning"
                title={
                  <p className="tw-font-extrabold tw-text-4xl tw-mb-5">
                    Whoops!
                  </p>
                }
                subTitle={
                  <p className=" tw-font-medium tw-text-2xl">
                    Something seems broken!
                  </p>
                }
                // extra={

                // }
                className=" tw-px-8 tw-py-8"
                // style={{ padding: "30px 32px" }}
              />
              <Button
                disabled
                type="primary"
                key="console"
                className=" tw-flex tw-flex-wrap tw-w-full tw-h-full tw-mb-10"
                style={{
                  textWrap: "wrap",
                }}
              >
                Please refresh and if the problem persists then please check
                your internet connection and try after some time.
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NoInternet;
