import { Input, Pagination, Popconfirm, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineDeleteSweep } from "react-icons/md";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";
import { filterData, notifications } from "../../util/API/function";

const CRUDTable = (props) => {
  const {
    dataSource,
    Other = {},
    onChange,
    extraclass,
    isLoading = false,
    APIendpoint,
    DeleteSelectedRow = false,
    onConfirm = (value) => {
      console.log(value);
    },
    scrollAutoOff = false,
    scroll,
    setChanges = () => {},
    columns = [],
  } = props;
  const API = useHttp();
  const [selectedRows, setSelectedRows] = useState([]);
  // const [searchData, setSearchData] = useState(dataSource);
  // const [value, setValue] = useState();
  const rowSelection = {
    onChange: DeleteSelectedRow
      ? (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRowKeys);
        }
      : onChange,
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  // const isDataEmpty = dataSource?.length <= 0;
  // let CSVData = [];

  // if (exportData?.length) {
  //   if (!isDataEmpty) {
  //     CSVData[0] = exportData?.map((el) => el[1]);
  //     renderData?.map((item, index) => {
  //       CSVData[index + 1] = exportData
  //         ?.map((el) => el[0])
  //         ?.map((val) => {
  //           if (item != null && val in item) return item[val];
  //           return "";
  //         });
  //       return 0;
  //     });
  //   }
  // } else {
  //   CSVData[0] = CONSTANTS.TABLE[name]?.map((el) => el.title);
  //   renderData?.map((item, index) => {
  //     CSVData[index + 1] = CONSTANTS.TABLE[name]?.map(
  //       (el) => item[el.dataIndex]
  //     );
  //     return 0;
  //   });
  // };

  // useEffect(() => {
  //   setSearchData(dataSource);
  // }, [dataSource]);

  return (
    <>
      {selectedRows.length && DeleteSelectedRow ? (
        <Row className="mb20">
          <Popconfirm
            title="Sure to delete selected rows in bulk?"
            onConfirm={() => {
              const rows = [...selectedRows];
              rows?.forEach((el, i) => {
                if (CONSTANTS?.API[APIendpoint]) {
                  const DeleteAPIBulk = { ...CONSTANTS?.API[APIendpoint] };
                  DeleteAPIBulk.endpoint = DeleteAPIBulk?.endpoint?.replace(
                    ":id",
                    el
                  );
                  API.sendRequest(DeleteAPIBulk, () => {
                    if (rows?.length - 1 === i) {
                      onConfirm(rows);
                      setSelectedRows([]);
                      notifications.success("All deleted successfully");
                    }
                  });
                }
              });
            }}
          >
            {
              <MdOutlineDeleteSweep
                style={{ color: "black" }}
                fontSize={35}
                className="ml20 cursor-pointer"
              />
            }
          </Popconfirm>
        </Row>
      ) : (
        ""
      )}
      <div>
        {/* <Input
          className=" tw-w-56 tw-mb-4"
          placeholder="Search"
          value={value}
          onChange={(e) => {
            const currValue = e.target.value;
            const columnKey = columns?.map((el) => el?.dataIndex);
            console.log(columnKey);
            const filteredData = filterData(dataSource, columnKey, currValue);

            console.log(filteredData);
            setSearchData(filteredData);
            // setValue(currValue);
            // const filteredData = data.filter((entry) =>
            //   entry.name.includes(currValue)
            // );
            // setDataSource(filteredData);
            // setSearchData();
          }}
        /> */}
        <Table
          rowClassName={`rows-custom ${extraclass}`}
          loading={isLoading}
          pagination={false}
          onChange={(page, filter, sort) => {
            const newFilter = [];
            for (const property in filter) {
              if (filter[property]) {
                newFilter.push([property, filter[property]]);
              }
            }
            const NewSort = {
              sort: sort?.field,
              sortBy:
                sort?.order === "ascend"
                  ? "ASC"
                  : sort?.order === "descend"
                  ? "DESC"
                  : null,
            };
            const NewChanges = {};
            if (NewSort.sortBy && NewSort?.sort !== "no") {
              NewChanges.sort = NewSort;
            }
            if (newFilter.length) {
              NewChanges.filter = newFilter;
            }
            setChanges(NewChanges);
          }}
          // scroll={
          //   !scrollAutoOff
          //     ? {
          //         x: "80vw",
          //         y: "60vh",
          //       }
          //     : scroll
          //     ? scroll
          //     : {}
          // }
          scroll={
            scroll ? scroll : !scrollAutoOff ? { x: "80vw", y: "40vh" } : {}
          }
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          // }}
          dataSource={dataSource}
          columns={columns}
          {...Other}
        />
        {/* <Pagination
          current={pageNumber}
          pageSize={pageSize}
          total={totalRecords}
          showSizeChanger
          onChange={handleChangePage}
          className="mt-16"
        /> */}
      </div>
    </>
  );
};
CRUDTable.defaultProps = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};
export default CRUDTable;
