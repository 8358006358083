import { Card } from "antd";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import HubspotForm from "react-hubspot-form";
import Loader from "../../component/common/loader";
import { FormSubmitAlert } from "../../util/API/function";

const SupprtPage = (props) => {
  const [sweet_timer, setSweet_timer] = useState(false);
  const [formLoaded, setFormLoaded] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "39660215",
          formId: "ce85fa6c-4e2b-4929-9515-1fd1e5ee8f21",
          target: "#hubspotForm",
          onFormReady: () => setFormLoaded(false),
          onFormSubmit: () => setSweet_timer(true),
        });
      }
    });
  }, []);

  return (
    <>
      {/* {formLoaded ? (
        <Loader />
      ) : ( */}
      <Card className=" tw-mt-5">
        {/* <HubspotForm
          portalId="39660215"
          formId="ce85fa6c-4e2b-4929-9515-1fd1e5ee8f21"
          onSubmit={() => {
            console.log("Submit!");
            setSweet_timer(true);
          }}
          onReady={(form) => {
            console.log("Form ready!");
            // setSweet_timer(true);
          }}
          loading={<div></div>}
        /> */}
        {formLoaded && <Loader />}
        <div id="hubspotForm"></div>
      </Card>
      {/* )} */}

      {sweet_timer ? (
        <FormSubmitAlert
          title="Thanks for submitting the form!."
          timeout={3000}
          setSweet_timer={setSweet_timer}
          msg=" Please wait while we process your data..."
        />
      ) : (
        <></>
      )}

      {/* {sweet_timer ? (
        <SweetAlert
          title={`Thanks for submitting the form!.`}
          timeout={3000}
          success
          showConfirm={false}
          onConfirm={() => {
            setSweet_timer(false);
          }}
        >
          Please wait while we process your data...
        </SweetAlert>
      ) : null} */}
    </>
  );
};

export default SupprtPage;
