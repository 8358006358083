import React from "react";
import CRUDComponent from "../../component/common/CRUD-Component";
import CONSTANTS from "../../util/constant/CONSTANTS";
import { Card, Row } from "antd";
import UsageServices from "../../util/API/usageService";
import { TeamMasK } from "../../util/spread-function";

const UsagePage = () => {
  return (
    <>
      <Row className=" tw-mt-10">
        <CRUDComponent
          isSearch
          service={UsageServices}
          GET={{
            API: CONSTANTS.API.USAGE.get,
            // DataModifier: (res) => {
            //   return TeamMasK(res);
            // },
            DataModifier: (res) => {
              const filterData = res
                ?.map((item) => ({
                  ...item,
                  data: [
                    ...item.data
                      .filter((subItem) => subItem.status === "success")
                      .sort((a, b) => b.count - a.count),
                    ...item.data.filter((subItem) => subItem.status === "fail"),
                  ],
                }))
                .sort((a, b) => {
                  const aSuccessCount = a.data.reduce(
                    (acc, subItem) => acc + subItem.count,
                    0
                  );
                  const bSuccessCount = b.data.reduce(
                    (acc, subItem) => acc + subItem.count,
                    0
                  );
                  return bSuccessCount - aSuccessCount;
                });

              return filterData?.map((res, index) => {
                const successCount = res?.data?.find(
                  (el) => el?.status == "success"
                )?.count;
                const failedCount = res?.data?.find(
                  (el) => el?.status == "fail"
                )?.count;
                return {
                  ...res,
                  no: res?.id || index,
                  category: res?.subCategory?.name,
                  usageCount: successCount || 0,
                  usageFailedCount: failedCount || 0,
                  // roleColomn:
                  //   res?.role == "client"
                  //     ? "Client Admin"
                  //     : res?.role == "siteViewer"
                  //     ? "Site Admin"
                  //     : "Shipment Creator",
                };
              });
            },
            //   extraQuery: {
            //     id: "1",
            //   },
            column: CONSTANTS.TABLE.USAGE_TABLE,
          }}
          //   isPaginationOff
          // UPDATE={{
          //   API: CONSTANTS.API.Technology.update,

          //   message: "Updated Technology successfully",
          //   modaltitle: "Update Technology",
          //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
          //   // payloadModifier: (res) => res,
          // }}
          // CREATE={{
          //   API: CONSTANTS.API.USAGE.create,
          //   message: "Created Access successfully",
          //   modaltitle: "Add Access",
          //   modalFields:
          //     localStorage.getItem("userRole") == "client"
          //       ? CONSTANTS.FORM_FIELD?.CROLETEAM
          //       : CONSTANTS.FORM_FIELD?.PROLETEAM,
          //   // payloadModifier: (res) => res,
          // }}
          // DELETE={{
          //   API: CONSTANTS.API.ROLEANDACCESS.delete,
          //   message: "Deleted Successfully",
          // }}
        />
      </Row>
    </>
  );
};

export default UsagePage;
