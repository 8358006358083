import { Modal, Table } from "antd";
import React from "react";
import CONSTANTS from "../../util/constant/CONSTANTS";
import { getTableColumConfig } from "../../util/API/function";

const BillHistoryModal = (props) => {
  const { title, open, onCancel, data } = props;
  return (
    <>
      <Modal
        title={title}
        open={open}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => onCancel(false)}
        width={1000}
      >
        <div className=" tw-h-[500px]">
          <Table
            dataSource={data}
            columns={CONSTANTS.TABLE.BILLDETAILMODAL?.map((dataKey) =>
              getTableColumConfig(dataKey)
            )}
            scroll={{ x: 800, y: 450 }}
            pagination={false}
          />
        </div>
      </Modal>
    </>
  );
};

export default BillHistoryModal;
