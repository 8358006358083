import { Card, Col, Row } from "antd";
import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiCreditCard, BiTask } from "react-icons/bi";
import AutoBreadcrumb from "./Breadcrumb";
import { formatMoney } from "../util/API/function";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES, clientRoute } from "../util/constant/CONSTANTS";
import { subscriptionSpreadData } from "../util/spread-function";
import { FaInfinity } from "react-icons/fa";

const PageTitle = (props) => {
  let { breadcrumbItems, getMeData, subscriptionData } = props;
  subscriptionData = subscriptionSpreadData(subscriptionData);
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Card className=" tw-mt-5">
      {" "}
      <Row>
        <Col
          xs={24}
          sm={10}
          md={14}
          lg={17}
          xl={19}
          xxl={20}
          className=" tw-mb-3"
        >
          <Row className="mt-5">
            <AutoBreadcrumb items={breadcrumbItems} />
          </Row>
          {/* <p>Hello, Akshay Tandon</p> */}
          <p className=" tw-text-xl tw-font-semibold  tw-font-poppins">
            Hello, {getMeData?.data?.name || ""}
          </p>
        </Col>
        <Col
          xs={24}
          sm={14}
          md={10}
          lg={7}
          xl={5}
          xxl={4}
          className=" tw-flex tw-items- tw-justify-center tw-flex-col"
        >
          {/* <Row
            className=" tw-mr-4 tw-mb-3 tw-flex color tw-items-center "
            gutter={[8, 8]}
          >
           
            <Col
              xs={14}
              className=" tw-text-lg tw-text-black tw-font-medium tw-font-bold tw-m-0 "
            >
              Testing Credits
            </Col>
            <Col xs={10}>
              <Row className="tw-text-xl tw-gap-2 tw-flex tw-max-w-[7rem] tw-min-w-[7rem] tw-justify-start tw-border-[#8f9bb3]  tw-items-center tw-border tw-border-solid tw-rounded-md tw-bg-white tw-px-3 tw-py-1">
                <Col
                  xs={5}
                  md={5}
                  lg={5}
                  className="tw-text-xl tw-flex tw-items-center"
                >
                  <BiTask color={"#faab14"} />
                </Col>
                <Col md={16} lg={14}>
                  <Col className="tw-font-semibold tw-text-xl   tw-m-0 ">
                    {+(
                      subscriptionData?.planQuota -
                      subscriptionData?.planQuotaUsed
                    )?.toFixed() || "00"}
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row> */}
          {/* {subscriptionData?.planType !== "payg" ? ( */}
          <Row
            className=" tw-mr-4 tw-mb-3 tw-flex color tw-items-center "
            gutter={[8, 8]}
          >
            <Col xs={24} sm={14} md={14} lg={14}>
              <Row>
                <Col xs={3} sm={4} lg={4} className=" tw-flex tw-items-center">
                  {" "}
                  <AiFillPlusCircle
                    color="#45cb85"
                    size={"25px"}
                    height="25px"
                    className=" tw-cursor-pointer"
                    onClick={() =>
                      navigate(`${clientRoute}/${id}/${ROUTES.ADMIN_PLAN}`)
                    }
                  />
                </Col>
                <Col
                  xs={19}
                  sm={20}
                  md={20}
                  lg={20}
                  className=" tw-text-lg tw-text-black  tw-font-semibold tw-m-0  tw-font-poppins"
                >
                  {" "}
                  Live Credits
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={10} lg={10} className=" !tw-flex !tw-justify-end">
              <Row className="tw-text-xl tw-gap-2 tw-flex tw-max-w-[7rem] tw-min-w-[7rem] tw-items-center  tw-justify-start tw-border-[#8f9bb3]  tw-items-center tw-border tw-border-solid tw-rounded-md tw-bg-white tw-px-3 tw-py-1">
                <Col
                  xs={5}
                  md={5}
                  lg={5}
                  className="tw-font-semibold tw-text-xl tw-flex tw-justify-center tw-items-center"
                >
                  <BiCreditCard color={"#23b887"} />
                </Col>
                <Col
                  md={16}
                  lg={14}
                  className=" tw-flex tw-justify-center tw-items-center tw-font-semibold tw-text-xl"
                >
                  {/* <Col className="tw-font-semibold tw-text-xl tw-justify-center tw-justify-center   tw-m-0"> */}
                  {subscriptionData?.planType == "payg" ? (
                    <FaInfinity className=" tw-ml-3" size={25} />
                  ) : (
                    subscriptionData?.totalAllQuota || "00000"
                  )}
                  {/* </Col> */}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* ) : null} */}
        </Col>
      </Row>
    </Card>
  );
};

export default PageTitle;
