import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Avatar, Col, Drawer, Layout, Menu, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import CONSTANTS, {
  adminRoute,
  clientRoute,
} from "../../util/constant/CONSTANTS";
import Profile from "../../asset/images/dummy-avatar.jpg";
import useHttp from "../../hooks/use-http";
import { menu as items } from "../../util/constant/menu";
import { menuGen, notifications } from "../../util/API/function";
import PageTitle from "../pageTitle";
import { Footer } from "antd/es/layout/layout";
import { isLogin } from "../../util/API/function";
import CustomHeader from "../common/header";
import useCheckInternet from "../../hooks/use-checkInternet";

const { Header, Content } = Layout;
const { Text } = Typography;
const AppLayout = (props) => {
  useCheckInternet("/noInternet");
  const { user_details, subscriptionData, is_loading, update_profile } = props;

  const { id } = useParams();
  const navigate = useNavigate();
  const API = useHttp();
  const location = useLocation();
  const currentPath = location.pathname;
  const [sidebarVisible, setSideBarVisible] = useState(false);

  const urlParams = new URLSearchParams(location?.search);

  const breadcrumbItems = [];
  let activeMenuItemIndex = items.findIndex(
    (item) => currentPath == item.path.replace(":id", id)
  );
  activeMenuItemIndex = `tmp-${activeMenuItemIndex}`;

  const onMenuChange = (e) => {
    if (e.keyPath.length) {
      if (!items[+e.key.replace("tmp-", "")]?.path?.includes("https://")) {
        navigate(items[+e.key.replace("tmp-", "")]?.path?.replace(":id", id));
      } else {
        window.open(`${items[+e.key.replace("tmp-", "")]?.path}`, "_blank");
      }
    }
  };

  useEffect(() => {
    if (urlParams?.get("token")) return;
    if (!isLogin()) {
      // navigate(loginRoot);
      notifications.error("Session Expired!");
      navigate(`/login`);
    } else {
      // if (user_details?.data?.role != "client") return navigate(appRoot);
      if (!user_details) return;
      if (user_details?.data?.role == "admin") return navigate(adminRoute);

      if (currentPath == clientRoute) {
        user_details?.data?.clientId &&
          navigate(currentPath + `/${user_details?.data?.clientId}`);
      }
      if (currentPath == `${clientRoute}/`) {
        user_details?.data?.clientId &&
          navigate(currentPath + `${user_details?.data?.clientId}`);
      }
      if (
        !currentPath?.includes(user_details?.data?.clientId) &&
        currentPath != clientRoute &&
        currentPath != `${clientRoute}/`
      ) {
        user_details?.data?.clientId &&
          navigate(
            currentPath?.replace(
              currentPath.split("/")[2],
              user_details?.data?.clientId
            )
          );
      }
    }
  }, [user_details]);

  // place it before activeMenuItemIndex update
  const pageFind = items?.find((el) => currentPath.includes(el?.id))?.label;

  pageFind && breadcrumbItems.push({ title: pageFind });

  const onClose = () => {
    setSideBarVisible(false);
  };

  return (
    <>
      <Layout
        hasSider={true}
        style={{
          minHeight: "100vh",
          // userSelect: "none",
        }}
      >
        <Layout className=" ">
          <CustomHeader
            Header={Header}
            setSideBarVisible={setSideBarVisible}
            onMenuChange={onMenuChange}
            activeMenuItemIndex={activeMenuItemIndex}
          />
          <Drawer
            width={250}
            placement="right"
            closable={false}
            onClose={onClose}
            open={sidebarVisible}
            className="tw-h-4/5"
          >
            <div className="flex-x center text-center profile-drawer">
              <div className="tw-flex tw-flex-col tw-items-center">
                <Avatar
                  size={80}
                  style={{
                    color: "#fffff",
                    backgroundColor: "#000000",
                    marginBottom: "20px",
                  }}
                  className="mt-10"
                  src={Profile}
                >
                  <div style={{ fontWeight: "400", fontSize: "2rem" }}>
                    {/* {UserData.fullname.split(" ")[0].charAt(0).toUpperCase()} */}
                  </div>
                </Avatar>
                {console.log(currentPath)}
                <Menu
                  selectedKeys={activeMenuItemIndex}
                  style={{ borderInlineEnd: "0px" }}
                  mode="inline"
                  items={items || []}
                  onClick={onMenuChange}
                />
              </div>
            </div>
            <hr className="my30" style={{ background: "#E4E8F0" }} />
          </Drawer>

          <Content className="px-1 tw-mx-8 md:px-10 ">
            <PageTitle
              breadcrumbItems={breadcrumbItems}
              getMeData={user_details}
              subscriptionData={subscriptionData}
            />

            <Outlet />
          </Content>
          <Footer className=" tw-text-start tw-mt-5 tw-py-5 tw-bg-[#e3e4e5]">
            <Row>
              <Col md={6}>{new Date().getFullYear()} © Fitsol.</Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
