import { alphanumericSort } from "./API/function";

export const TABLE_CONFIG = {
  no: {
    title: <p style={{ margin: "0px", width: "70px" }}>Record Id</p>,
    dataIndex: "no",
    sorter: (a, b) => alphanumericSort(a, b, "no"),
  },
  name: {
    title: <p style={{ margin: "0px", width: "120px" }}>Name</p>,
    dataIndex: "name",
    sorter: (a, b) => alphanumericSort(a, b, "name"),
  },
  email: {
    title: <p style={{ margin: "0px", width: "120px" }}>Email ID</p>,
    dataIndex: "email",
    sorter: (a, b) => alphanumericSort(a, b, "email"),
  },
  roleColomn: {
    title: <p style={{ margin: "0px", width: "120px" }}>Role</p>,
    dataIndex: "roleColomn",
    sorter: (a, b) => alphanumericSort(a, b, "roleColomn"),
  },
  client: {
    title: <p style={{ margin: "0px", width: "120px" }}>Customer</p>,
    dataIndex: "client",
    sorter: (a, b) => alphanumericSort(a, b, "client"),
  },
  mobile: {
    title: <p style={{ margin: "0px", width: "100px" }}>Mobile Number</p>,
    dataIndex: "mobile",
    sorter: (a, b) => alphanumericSort(a, b, "mobile"),
  },
  planName: {
    title: <p style={{ margin: "0px", width: "100px" }}>Plan Name</p>,
    dataIndex: "planName",
    sorter: (a, b) => alphanumericSort(a, b, "planName"),
  },
  planStartDate: {
    title: <p style={{ margin: "0px", width: "100px" }}>Plan Startdate</p>,
    dataIndex: "planStartDate",
    sorter: (a, b) => alphanumericSort(a, b, "planStartDate"),
  },
  planEndDate: {
    title: <p style={{ margin: "0px", width: "100px" }}>Plan Enddate</p>,
    dataIndex: "planEndDate",
    sorter: (a, b) => alphanumericSort(a, b, "planEndDate"),
  },
  usage: {
    title: <p style={{ margin: "0px", width: "100px" }}>Usage</p>,
    dataIndex: "usage",
    sorter: (a, b) => alphanumericSort(a, b, "usage"),
  },
  billAmount: {
    title: <p style={{ margin: "0px", width: "100px" }}>Bill Amount</p>,
    dataIndex: "billAmount",
    sorter: (a, b) => alphanumericSort(a, b, "billAmount"),
  },

  reset: {
    title: (
      <p style={{ margin: "0px", width: "70px", textAlign: "center" }}>
        Reset Password
      </p>
    ),
    dataIndex: "reset",
    // sort: "disabled",
  },
};
