import { Avatar, Col, Dropdown, Image, Menu, Row, Space } from "antd";
import React, { useState } from "react";
import { FaListUl } from "react-icons/fa";

import logo from "../../asset/logo/logo-light.png";
import logoS from "../../asset/logo/logo-sm.png";
import defaultImage from "../../asset/images/image2.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { menuGen } from "../../util/API/function";
import { rightBarMenu, menu as items } from "../../util/constant/menu";

const CustomHeader = (props) => {
  const {
    Header,
    setSideBarVisible,
    onMenuChange = () => {},
    activeMenuItemIndex,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location?.pathname;

  return (
    <Header className=" tw-bg-primary tw-rounded-xl tw-sticky tw-top-4  tw-z-50 tw-mx-8   tw-my-3 tw-p-0 tw-shadow-lg ">
      <Row className=" !tw-rounded-lg   md:tw-flex tw-hidden">
        <Col
          md={4}
          lg={4}
          xl={3}
          xxl={2}
          className="tw-items-center tw-justify-center md:tw-flex tw-hidden"
        >
          <Image
            className=" tw-cursor-pointer !tw-h-10 !tw-w-full tw-p-[5px] tw-rounded-[7px] tw-bg-white tw-text-transparent"
            preview={false}
            src={logo}
            onClick={() => {
              navigate(`${"/"}`);
            }}
          />
        </Col>

        <Col md={17} lg={18} xl={18} xxl={17} className="md:tw-block tw-hidden">
          <Menu
            className="ms-3 hidden md:block min-w-fit w-96 horizontal-Menu"
            selectedKeys={activeMenuItemIndex}
            activeKey=""
            mode="horizontal"
            items={items}
            // menuGen(menu) || []}
            onClick={onMenuChange}
          />
        </Col>

        <Col
          md={3}
          lg={2}
          xl={3}
          xxl={5}
          className="tw-flex tw-items-center tw-justify-end tw-px-3"
        >
          {/* <ProfileMenu /> */}
          <Dropdown
            className="hidden md:flex "
            menu={rightBarMenu(id, navigate)}
          >
            <Space>
              <Avatar
                src={defaultImage}
                size={35}
                style={{
                  border: "3px solid #ffffff2e",
                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
              />
            </Space>
          </Dropdown>
          {/* <FaListUl className="d-flex md:d-none d-hidden tw-text-white" /> */}
        </Col>
      </Row>
      <Row className=" md:tw-hidden tw-flex tw-justify-between tw-items-center  !tw-rounded-lg ">
        <Col
          xs={4}
          // sm={5}
          // md={4}
          className=" tw-flex tw-items-center tw-justify-center tw-ml-1"
        >
          <Image
            className="tw-cursor-pointer  !tw-h-10 !tw-w-full tw-p-[5px] tw-rounded-[7px] tw-bg-white tw-text-transparent"
            preview={false}
            src={logoS}
            onClick={() => {
              navigate(`${"/"}`);
            }}
          />
        </Col>
        <Col
          // xs={17}
          sm={4}
          md={17}
          className="tw-flex tw-items-center tw-justify-start tw-mx-2"
        >
          <FaListUl
            className=" tw-text-white tw-mx-4 tw-cursor-pointer"
            size={15}
            onClick={() => setSideBarVisible((pr) => !pr)}
          />
          <Dropdown className="" menu={rightBarMenu(id, navigate)}>
            <Space>
              <Avatar
                src={defaultImage}
                size={35}
                style={{
                  border: "3px solid #6866cc",
                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
              />
            </Space>
          </Dropdown>
        </Col>
      </Row>
      <div className="demo-logo" />
    </Header>
  );
};

export default CustomHeader;
