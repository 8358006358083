import React, { useEffect } from "react";
import { deleteAuthDetails } from "../../util/API/authStorage";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    deleteAuthDetails();

    localStorage.clear();
    navigate("/");
  }, []);

  return <></>;
};

export default Logout;
