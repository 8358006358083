import { Breadcrumb } from "antd";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { appRoot, clientRoute } from "../util/constant/CONSTANTS";

const AutoBreadcrumb = ({ items = [] }) => {
  const { id } = useParams();
  return (
    <Breadcrumb
      className=" text-base"
      items={[
        {
          title: <Link to={`${clientRoute}/${id}`}>Home</Link>,
        },
        ...items,
      ]}
    />
  );
};

export default AutoBreadcrumb;
