import axios from "axios";
import { getCookie } from "../Cookies";
const UsageServices = axios.create({
  baseURL: process.env.REACT_APP_USAGE_URL,
  // timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${
      getCookie()
      // document.cookie ? document.cookie.replace("SAID=", "") : null
    }`,
  },
});

export default UsageServices;
