import { Button, Image, Input, Popconfirm, Switch } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import Eye from "../../asset/images/eye.png";
import logo from "../../asset/logo/logo-sm-dark.png";
import moment from "moment/moment";
import { alphanumericSort } from "../API/function";
import {
  clientForRoleId,
  customerPassword,
  email,
  mobile,
  name,
  plantForRoleId,
  plantId,
  profileName,
  roleWise,
} from "../models/form-model";

export const loginRoot = "/";
export const appRoot = "/app";
export const adminRoute = "/admin";
export const clientRoute = "/client";
// export const onBoard = "/onboard";
export const serviceRoot = "/service";
export const projectRoot = "/project";

// const alphanumericSort = (a, b, value) => {
//   if (isNaN(parseInt(a[value]))) {
//     return a[value]?.localeCompare(b[value]);
//   }
//   return a[value] - b[value];
// };

const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
const RenderDeleteButton = (value) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
const RenderEditButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

//Link Button
const RenderLinkButton = (value) => {
  return (
    <Button type="primary" href={value}>
      Config
    </Button>
  );
};

// View Image Button
const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={Eye}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
const RenderViewButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <EyeOutlined />
    </Button>
  );
};

// Render Images
const ImageRanders = (value) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className="rounded-full object-cover"
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

// Toggle Button
const RenderToggleButton = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, checked);
        }}
        checked={checked}
      />
    </div>
  );
};

// Checkbox render
const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

export const ROUTES = {
  dashboard: "/dashboard",
  services: "/:id",
  log: "/:id/log",
  setup: "/:id/setup",
  history: "/:id/history",

  //AUTHROUTEPATH
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  REGISTER: "/register",
  PAGESMAINTENANCE: "/pages-maintenance",
  PAGESCOMINGSOON: "/pages-comingsoon",
  PAGES404: "/pages-404",
  PAGES500: "/pages-500",
  PAGES403: "/pages-403",
  PAGESUNAUTHORISED: "/pages-401",
  DATANOTFOUND: "/data-not-found",
  LOGIN1: "/pages-login",
  REGISTER1: "/pages-register",
  RECOVERPW: "/page-recoverpw",
  LOCKSCREEN: "/auth-lock-screen",

  //ADMIN
  ADMIN_DASHBOARD: "/",
  ADMIN_CREDIT: "credit",
  ADMIN_PLAN: "plan",
  ADMIN_SUPPORT: "support",
  ADMIN_CREDENTIALS: "Credentials",
  ADMIN_USAGE: "usage_insights",
  ADMIN_PROFILE: "profile",
  // ADMIN_: "/",
};

export const planDetail = [
  {
    title: "Trial",
    description:
      "For the curious you! Try our platform for free and see how it simplifies your data collection processes and net zero enablement.",
    amount: "₹0",
    planFeatureDetail: [
      "Data Collection - upto 500 entries",
      "Carbon Footprint Measurement",
      "Reporting",
    ],
  },
  {
    title: "Pro",
    description:
      "Enhance your organization's approach to carbon and ESG reporting across various locations, leverage cutting-edge decarbonization tools, and develop a unified strategy for sustainability.",
    amount: "₹50,000",
    planFeatureDetail: [
      "Data Collection - upto 20,000 entries",
      "Multi facilities management",
      "Carbon Footprint Measurement",
      "Reporting - including BRSR",
      "Reporting - including BRSR",
    ],
    highLightCard: true,
  },
  {
    title: "Custom",
    description:
      "Customised plan to scale your sustainability efforts. Everything in Pro plus Expert ESG Guidance, Regular Employee Trainings, and Net Zero Workflow Management",
    amount: "--",
    perPlan: "Contact for pricing",
    planFeatureDetail: [
      "All Pro plan features",
      "API Integration",
      "Custom emissions calculation",
      "ESG Management module",
      "Regulatory compliant reporting",
    ],
  },
];

export const subscriptionDetailCard = {
  title: "Subscription detail",
  cardFields: [
    {
      label: "Plan Name",
      field: "planName",
    },
    {
      label: "Plan Cost (in Rs)",
      field: "price",
    },
    {
      label: "Plan Duration",
      field: "planDuration",
    },
    {
      label: "Plan Activation Date",
      field: "planCreationDate",
    },
    {
      label: "Plan Renewal Date",
      field: "planRenewalDate",
    },
  ],
};
export const payasyougoCard = {
  title: "Usage Details",
  cardFields: [
    {
      label: "Billable Quota",
      field: "payAsYouGobillableQuata",
      // PayAsYouGo: true,
    },
    {
      label: "Per Quota price",
      field: "payAsYouGoperQuotaPrice",
      // PayAsYouGo: true,
    },
    {
      label: "Total",
      field: "payAsYouGototalBillableAmount",
      // PayAsYouGo: true,
    },
  ],
};
export const fixedCard = {
  title: "Usage Details",
  cardFields: [
    [
      {
        label: "Available Quota",
        field: "totalAvailableQuota",
      },
      {
        label: "Used Quota",
        field: "usedQuota",
      },
      {
        label: "Available Quota",
        field: "remainingQuota",
        break: true,
      },
    ],
    [
      {
        label: "Add-on Quota",
        field: "totalAddOnQuota",
      },
      {
        label: "Add-on Quota Used",
        field: "addOnQuotaUsed",
      },
      {
        label: "Add-on Quota Available",
        field: "remainingaddOnQuota",
        break: true,
      },
    ],
    [
      {
        label: "Total Available Quota",
        field: "totalAllQuota",
        highlight: true,
      },
    ],
  ],
};

export const billingDetailCard = {
  title: "Billing Details",
  helpbtn: true,
  cardFields: [
    {
      label: "Next Bill date",
      field: "lastBillDate",
    },
    {
      label: "Bill Due Date",
      field: "billDueDate",
    },
    {
      label: "Bill amount (in Rs)",
      field: "billAmount",
    },
  ],
};

const card2 = {
  title: "Usage Details",
  loader: true,
  // fixedplanFields : [
  //   {
  //     label: "Available Quota",
  //     field: "totalAvailableQuota",
  //   },
  //   {
  //     label: "Used Quota",
  //     field: "usedQuota",
  //   },
  //   {
  //     label: "Available Quota",
  //     field: "remainingQuota",
  //     break: true,
  //   },
  //   {
  //     label: "Add-on Quota",
  //     field: "totalAddOnQuota",
  //   },
  //   {
  //     label: "Add-on Quota Used",
  //     field: "addOnQuotaUsed",
  //   },
  //   {
  //     label: "Add-on Quota Available",
  //     field: "remainingaddOnQuota",
  //     break: true,
  //   },
  //   {
  //     label: "Total Available Quota",
  //     field: "totalAllQuota",
  //     highlight: true,
  //   },
  // ],
  // payasyougoPlanFields : [
  //   {
  //     label: "Billable Quota",
  //     field: "payAsYouGobillableQuata",
  //     PayAsYouGo: true,
  //   },
  //   {
  //     label: "Per Quota price",
  //     field: "payAsYouGoperQuotaPrice",
  //     PayAsYouGo: true,
  //   },
  //   {
  //     label: "Total",
  //     field: "payAsYouGototalBillableAmount",
  //     PayAsYouGo: true,
  //   },
  // ],
  cardFields: {
    payasyouGo: [
      [
        //payasyougo
        {
          label: "Billable Quota",
          field: "payAsYouGobillableQuata",
          // PayAsYouGo: true,
        },
        {
          label: "Per Quota price",
          field: "payAsYouGoperQuotaPrice",
          // PayAsYouGo: true,
        },
        {
          label: "Total",
          field: "payAsYouGototalBillableAmount",
          // PayAsYouGo: true,
        },
      ],
    ],
    quotaBase: [
      [
        {
          label: "Available Quota",
          field: "totalAvailableQuota",
        },
        {
          label: "Used Quota",
          field: "usedQuota",
        },
        {
          label: "Available Quota",
          field: "remainingQuota",
          break: true,
        },
      ],
      [
        {
          label: "Add-on Quota",
          field: "totalAddOnQuota",
        },
        {
          label: "Add-on Quota Used",
          field: "addOnQuotaUsed",
        },
        {
          label: "Add-on Quota Available",
          field: "remainingaddOnQuota",
          break: true,
        },
      ],
      [
        {
          label: "Total Available Quota",
          field: "totalAllQuota",
          highlight: true,
        },
      ],
    ],
  },
};

export const createEndPoint = {
  get: (endpoint) => {
    return { endpoint, type: "GET" };
  },
  post: (endpoint) => {
    return { endpoint, type: "POST" };
  },
  patch: (endpoint) => {
    return { endpoint, type: "PATCH" };
  },
  delete: (endpoint) => {
    return { endpoint, type: "DELETE" };
  },
};

export const planCardDesignCardbg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="301"
    height="660"
    viewBox="0 0 201 436"
    fill="none"
  >
    <path
      opacity="0.3"
      d="M81 581C205.8 170.6 79 -8.66667 0 -47L149 -103L358 -110L430 140L471 376C289 615.333 -43.8 991.4 81 581Z"
      fill="white"
    />
  </svg>
);

export const roles = localStorage.getItem("userRole");

const CONSTANTS = {
  GETMe: null,
  TABLE: {
    // Service
    TEAM: [
      "no",
      "name",
      "email",
      "mobile",
      "roleColomn",
      "client",
      "reset",
      // "action",
    ],
    BILLDETAILMODAL: [
      // "no",
      "planName",
      "planStartDate",
      "planEndDate",
      "usage",
      "billAmount",
    ],
    SERVICE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 20,
      },

      {
        title: "Service Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Details",
        dataIndex: "details",
        render: RenderLinkButton,
        width: 30,
      },

      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 20,
      },

      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 20,
      },
    ],
    USAGE_TABLE: [
      // {
      //   title: " No.",
      //   dataIndex: "no",
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Category",
        dataIndex: "category",
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      // {
      //   title: "Rate",
      //   dataIndex: "rate",
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Usage Completed",
        dataIndex: "usageCount",
        // sorter: (a, b) => alphanumericSort(a, b, "usageCount"),
      },
      {
        title: "Usage Failed",
        dataIndex: "usageFailedCount",
        // sorter: (a, b) => alphanumericSort(a, b, "usageFailedCount"),
      },
      // {
      //   title: "Test Credits Consumed",
      //   dataIndex: "creditConsumed",
      //   sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
    ],
    BILL_HISTORY_TABLE: [
      {
        title: " No.",
        dataIndex: "no",
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Bill Duration",
        dataIndex: "billDuration",
        // sorter: (a, b) => alphanumericSort(a, b, "billDuration"),
      },
      {
        title: "Bill Date",
        dataIndex: "billDate",
        // sorter: (a, b) => alphanumericSort(a, b, "billDate"),
      },
      {
        title: "Bill Amount",
        dataIndex: "billAmount",
        // sorter: (a, b) => alphanumericSort(a, b, "billAmount"),
      },
      {
        title: "Bill Due Date",
        dataIndex: "billDueDate",
        // sorter: (a, b) => alphanumericSort(a, b, "billDueDate"),
      },
      {
        title: "View History",
        dataIndex: "viewHistory",
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    SUBCATEGORY_TABLE: [
      {
        title: "Id",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Role",
        dataIndex: "roleColomn",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Client",
        dataIndex: "client",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
  },
  FORM_FIELD: {
    // Project
    PROLETEAM: [
      roleWise,
      plantForRoleId,
      clientForRoleId,
      name,
      email,
      mobile,
      customerPassword,
    ],
    CROLETEAM: [plantId, name, email, mobile, customerPassword],
    PROJECT_MODAL: [
      {
        no: 1,
        Label: "Project Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Project Name",
        required: true,
      },
      {
        no: 1,
        Label: "Project Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Project Description",
        required: false,
      },
    ],
    ADMIN_PROFILE: [profileName],

    // Environment Modal
    EDIT_ENVIRONMENT_MODAL: [
      {
        no: 1,
        Label: "Environment Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Environment Name",
        required: false,
      },
    ],

    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
  },

  API: {
    LOGIN: {
      getMe: createEndPoint.get(`/auth/getMe`),
      roleGetMe: createEndPoint.get("/userManagement/getMe"),
      login: createEndPoint.post(`/auth/login`),
      adminUpdate: createEndPoint.patch(`/auth/updateMe`),
    },
    SUB_CATEGORY: {
      get: createEndPoint.get(`${roles}/subCategory`),
      // create: createEndPoint.post(`/admin/subCategory`),

      // update: createEndPoint.patch(`/admin/subCategory`),
      // delete: createEndPoint.delete(`/`),
    },
    SUBSCRIPTION: {
      get: createEndPoint.get(`/subscription`),
      create: createEndPoint.get(`/subscription`),
    },
    PLAN: {
      get: createEndPoint.get(`/plans`),
      create: createEndPoint.get(`/plans`),
      update: createEndPoint.patch(`/plans/:id`),
      delete: createEndPoint.delete(`/plans/:id`),
    },
    BILL: {
      get: createEndPoint.get(`/bill`),
      getTotalMonthlyBill: createEndPoint.get(
        `/bill/currBillAmount/?clientId=:id`
      ),
    },

    ROLEANDACCESS: {
      get: createEndPoint.get(`${roles}/userManagement/getAll`),
      create: createEndPoint.post(`${roles}/userManagement/signup`),
      update: createEndPoint.patch(`${roles}/userManagement/:id`),
      delete: createEndPoint.delete(`${roles}/userManagement/:id`),
      getClientRoleAndAccess: createEndPoint.get(
        `${roles}/userManagement/getAll?clientId=:id`
      ),
    },

    USAGE: {
      get: createEndPoint.get(`/analysis/briefAnalysis`),
      create: createEndPoint.post(`/`),

      update: createEndPoint.patch(`/`),
      delete: createEndPoint.delete(`/`),
      getCredentials: createEndPoint.get(`/usage/getCredentials`),
    },

    auth: {
      updateMe: {
        type: "PATCH",
        endpoint: "/me",
      },

      login: {
        type: "POST",
        endpoint: "/login",
      },

      getMe: {
        type: "GET",
        endpoint: "/me",
      },
    },
  },

  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "About Info",
        name: "about",
        id: "about",
      },
      {
        no: 2,
        Label: "Previous Bookings",
        name: "booking",
        id: "booking",
      },
      {
        no: 3,
        Label: "Car valet",
        name: "valet",
        id: "valet",
      },
      {
        no: 4,
        Label: "Chat History",
        name: "chat",
        id: "chat",
      },
      {
        no: 5,
        Label: "User Post",
        name: "post",
        id: "post",
      },
    ],
  },
};
export default CONSTANTS;
