import { Row } from "antd";
import React from "react";
import { FlippingSquare } from "react-cssfx-loading";

function Loader() {
  return (
    <Row className="  tw-flex tw-justify-center tw-mt-5">
      <div className=" tw-flex tw-flex-col tw-items-center">
        <FlippingSquare color="#3b5de7" />
        <pre style={{ color: "#3b5de7" }}>{`  Loading...`}</pre>
      </div>
    </Row>
  );
}

export default Loader;
