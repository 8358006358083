import { Button } from "antd";
import { getRecordId } from "./API/function";
import { FaEye } from "react-icons/fa";
import moment from "moment";

export const TeamMasK = (res, Operation, setShowModel_1, setActionData) =>
  res?.map((roleAndAccessData) => {
    return {
      ...roleAndAccessData,
      no: getRecordId?.roleAndAccessRecordId(roleAndAccessData?.id),
      client: `${
        roleAndAccessData?.role == "client"
          ? roleAndAccessData?.client?.name || ""
          : roleAndAccessData?.role == "siteViewer"
          ? roleAndAccessData?.plant?.client?.name || ""
          : "Fitsol"
      }`,
      roleColomn:
        roleAndAccessData?.role == "client"
          ? "Client Admin"
          : roleAndAccessData?.role == "siteViewer"
          ? "Site Admin"
          : "Shipment Creator",
      reset: (
        <Button
          color="primary"
          className="btn waves-effect waves-light"
          id="view"
          title="View"
          onClick={() => {
            // setShowModel_1((prev) => !prev);
            // setActionData(roleAndAccessData);
          }}
        >
          <i class="fas fa-user-lock"></i>
        </Button>
      ),
      //   action: ActionButton(Operation, roleAndAccessData),
    };
  });

export const BillHistoryMask = (res, onBillDetailHistory) =>
  res?.map((billDetail) => {
    return {
      ...billDetail,
      no: getRecordId?.billId(billDetail?.id),
      billDuration: billDetail?.billDuration,
      billDate: billDetail?.billDate,
      billAmount: `₹${billDetail?.billAmount?.toLocaleString()}`,
      billDueDate: billDetail?.billDueDate,
      viewHistory: (
        <Button
          type="primary"
          //   color="primary"
          //   className="btn waves-effect waves-light"
          id="view"
          title="View"
          onClick={() => {
            // setShowModel_1((prev) => !prev);
            // setActionData(roleAndAccessData);
            onBillDetailHistory(billDetail?.billDetails);
          }}
        >
          <FaEye />
        </Button>
      ),
    };
  });

export const subscriptionSpreadData = (subscriptionData, billAmountMonthly) => {
  const currentMonthYear = moment().format("MM-YYYY");
  const SubData = subscriptionData?.data?.rows;
  const AddOnBeforeToday = SubData?.filter(
    (el) => moment().utc().isBefore(el?.expiryDate) && el?.planType == "addon"
  );

  // Filter subscriptions for the current billing month
  const AddOnCurrentMonth = AddOnBeforeToday?.filter(
    (el) => moment(el?.billDate).utc().format("MM-YYYY") === currentMonthYear
  );

  const addOnPlan = SubData?.find(
    (el) => el?.status == "active" && el?.planType == "addon"
  );
  const currentPlan = SubData?.find(
    (el) => el?.status == "active" && el?.planType !== "addon"
  );

  const remainingQuota =
    currentPlan?.planQuota - currentPlan?.planQuotaUsed || "00";

  //Total Monthly Addon Quota
  let totalAddOnQuota = 0;
  AddOnBeforeToday &&
    AddOnBeforeToday?.map((el) => (totalAddOnQuota += el?.planQuota));

  let usedQuota = 0;
  AddOnBeforeToday &&
    AddOnBeforeToday?.map((el) => (usedQuota += el?.planQuotaUsed));
  //Total monthly Used Addon Quota
  let addOnQuotaUsed = 0;
  AddOnBeforeToday &&
    AddOnBeforeToday?.map((el) => (addOnQuotaUsed += el?.planQuotaUsed));

  //Total monthly remaining Addon Quota
  const remainingaddOnQuota = totalAddOnQuota - addOnQuotaUsed || "00";

  //Total Available Quota
  const totalAllQuota = +remainingQuota + +remainingaddOnQuota || "00";

  let billAmount = currentPlan?.plan?.price;
  if (currentPlan?.planType == "payg") {
    billAmount = +(
      currentPlan?.planQuotaUsed * currentPlan?.plan?.price
    )?.toFixed();
  } else {
    AddOnCurrentMonth &&
      AddOnCurrentMonth?.map((el) => (billAmount += el?.plan?.price));
  }

  return {
    ...currentPlan,
    planType: currentPlan?.planType || "No Active",
    planName: currentPlan?.plan?.name?.toUpperCase(),
    price: `₹${currentPlan?.plan?.price?.toLocaleString()}`,
    planDuration: currentPlan?.plan?.renewalPeriod?.toUpperCase() || "",
    planCreationDate: moment(currentPlan?.creationDate)?.format("DD-MM-YYYY"),
    planRenewalDate: moment(currentPlan?.renewalDate)?.format("DD-MM-YYYY"),
    totalAvailableQuota: currentPlan?.planQuota?.toLocaleString() || "00",
    usedQuota: currentPlan?.planQuotaUsed?.toLocaleString() || "00",
    remainingQuota: remainingQuota?.toLocaleString(),
    //addon
    totalAddOnQuota: totalAddOnQuota?.toLocaleString() || "00",
    // addOnPlan?.planQuota?.toLocaleString() || "00",
    addOnQuotaUsed: addOnQuotaUsed?.toLocaleString() || "00",
    remainingaddOnQuota: remainingaddOnQuota?.toLocaleString(),
    totalAllQuota: totalAllQuota?.toLocaleString(),
    payAsYouGobillableQuata: currentPlan?.planQuotaUsed?.toLocaleString(),
    payAsYouGoperQuotaPrice: currentPlan?.plan?.price?.toLocaleString(),
    payAsYouGototalBillableAmount: billAmount?.toLocaleString(),
    lastBillDate: moment(currentPlan?.billDate)?.format("DD-MM-YYYY"),
    billDueDate: moment(currentPlan?.billDate)
      ?.add(14, "days")
      ?.format("DD-MM-YYYY"),
    billAmount: `₹${billAmountMonthly?.toLocaleString()}`,

    // currentPlan?.plan?.price?.toLocaleString(),
  };
};
