import { useState, useCallback } from "react";
import Services from "../util/API/service";
import { notifications } from "../util/API/function";
const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(
    async (
      url,
      responseHandler,
      payload,
      services = Services,
      successMessage,
      errorHandler
    ) => {
      // let service;

      if (services == null) {
        services = Services;
      }

      setIsLoading(true);
      if (url?.endpoint && url?.type) {
        try {
          let response;
          switch (url.type) {
            case "POST":
              response = await services.post(url.endpoint, payload);
              break;

            case "PUT":
              response = await services.put(url.endpoint, payload);

              break;
            case "DELETE":
              response = await services.delete(url.endpoint);
              break;

            case "PATCH":
              response = await services.patch(url.endpoint, payload);
              break;

            default:
              const queryParams = buildQueryString(payload);

              response = await services.get(url.endpoint + queryParams);
              break;
          }

          const data = await response.data;
          if (successMessage) {
            notifications.success(successMessage);
          }
          try {
            if (responseHandler) {
              responseHandler(data);
            }
          } catch (e) {
            console.log(e);
          }
        } catch (err) {
          if (err?.response?.data?.message) {
            notifications.error(err?.response?.data?.message);
            if (errorHandler) {
              errorHandler(err?.response?.data?.message);
            }
          } else {
            notifications.error("Something Wrong Please Try again");
          }
        }
      } else {
        notifications.error("Not valid endpoint and type");
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;

export const buildQueryString = (params) => {
  const queryParts = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      if (key.startsWith("autogenerate-mul-array-") && Array.isArray(value)) {
        // Handle autogenerate-mul-array- with array values
        // console.log(key, "key");
        const arrayKey = key.slice("autogenerate-mul-array-".length);
        value.forEach((item) => {
          queryParts.push(
            `${encodeURIComponent(arrayKey)}=${encodeURIComponent(item)}`
          );
        });
      } else {
        // Handle other cases
        queryParts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
};
